import * as React from "react";
import { useAddToHomescreenPrompt } from "./useAddToHomescreenPrompt";

export function TestComponent() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = React.useState(false);

  const hide = () => setVisibleState(false);
  const toggleVisible = () => setVisibleState((v) => !v);

  React.useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);

  if (!isVisible) {
    return (
      <div>
        <p>Hello! isVisible is {isVisible ? "true" : "false"}</p>
        <button onClick={toggleVisible}>ToogleHide</button>
      </div>
    );
  }

  return (
    <div>
      <button onClick={hide}>Close</button>
      <p>Hello! Wanna add to homescreen?</p>
      <button onClick={promptToInstall}>Add to homescreen</button>
    </div>
  );
}
